import { createQueryParams } from "@/functions/urlUtils"

type UrlParams = {
    token: string
}

const path = "/live-booking/cancel"

export const createLiveBookingCancelUrl = (
    params: UrlParams,
    option?: Partial<{ queryParamOnly: boolean }>
) => {
    const queryParams = createQueryParams(params)
    return option?.queryParamOnly ? queryParams : path + queryParams
}
